var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col flex-grow" },
    [
      _c(
        "div",
        { staticClass: "flex flex-col flex-grow" },
        [
          _c(
            "div",
            { staticClass: "ml-4 py-3 pr-4 border-b border-blue-500 flex" },
            [
              _vm.searchValue
                ? _c(
                    "button",
                    {
                      staticClass: "focus:outline-none mr-2",
                      on: { click: _vm.back }
                    },
                    [
                      _c("img", {
                        staticClass: "w-4 h-4",
                        attrs: {
                          src: require("@/assets/images/chevron_left.svg")
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "form",
                {
                  staticClass: "w-full",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.loadIncidents($event)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchValue,
                        expression: "searchValue"
                      }
                    ],
                    staticClass: "form-input-blocked",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "the_incidents_overview.placeholder_search_incidents"
                      )
                    },
                    domProps: { value: _vm.searchValue },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchValue = $event.target.value
                      }
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "v-scrollable",
            { ref: "scrollable" },
            [
              !_vm.loading && _vm.incidents !== null
                ? _c("incidents-list", { attrs: { incidents: _vm.incidents } })
                : _vm._e(),
              _vm.loading ? _c("v-loading") : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.error
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }