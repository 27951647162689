<template>
  <div class="flex flex-col flex-grow">
    <div class="flex flex-col flex-grow">
      <div class="ml-4 py-3 pr-4 border-b border-blue-500 flex">
        <button
          v-if="searchValue"
          @click="back"
          class="focus:outline-none mr-2">
          <img
            src="@/assets/images/chevron_left.svg"
            class="w-4 h-4">
        </button>
        <form
          @submit.prevent="loadIncidents"
          class="w-full">
          <input
            type="text"
            v-model="searchValue"
            :placeholder="$t('the_incidents_overview.placeholder_search_incidents')"
            class="form-input-blocked"
          >
        </form>
      </div>
      <v-scrollable ref="scrollable">
        <incidents-list
          v-if="!loading && incidents !== null"
          :incidents="incidents"/>
        <v-loading v-if="loading"/>
      </v-scrollable>
    </div>
    <v-error
      v-if="error"
      v-model="error"/>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import IncidentsList from '@/components/lists/IncidentsList.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VError from '@/components/common/VError.vue';
  import infinityScroll from '@/mixins/infinityScroll';

  export default {
    name: 'TheIncidentsOverview',
    components: {
      VLoading,
      VScrollable,
      IncidentsList,
      VError,
    },
    mixins: [infinityScroll],
    data() {
      return {
        loading: true,
        searchValue: null,
        error: null,
      };
    },
    computed: {
      event() {
        return this.$store.getters['auth/event'];
      },
      incidents() {
        return this.$store.getters['incident/list'];
      },
    },
    created() {
      this.loadIncidents();

      this.$watch('searchValue', debounce(this.loadIncidents, 500));
    },
    beforeDestroy() {
      this.$store.commit('incident/clearList');
    },
    methods: {
      loadIncidents() {
        this.error = null;

        this.detachInfiniteScroll();

        this.$store.commit('incident/clearList');

        this.loading = true;

        this.$store.dispatch('incident/loadForEvent', {
          event: this.event,
          searchValue: this.searchValue,
        })
          .then(() => {
            this.loading = false;

            this.$nextTick().then(() => {
              this.attachInfiniteScroll(this.$refs.scrollable, () => {
                this.$store.dispatch('incident/loadForEvent', {
                  event: this.event,
                  searchValue: this.searchValue,
                });
              });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      back() {
        this.searchValue = null;
      },
    },
  };
</script>
